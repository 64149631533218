@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&display=swap');


body {
  background-color: white;
  margin: 0;
  padding: 0;
  /* background-color: black; */
}

/* Header */

.header {
  background-color: black;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  border-bottom: white 1px solid;
  position: relative;
}

.header img {
  width: 150px;
  margin-left: 10px;
}

.links-container {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.links {
  color: white;
  display: flex;
  flex-direction: row;
  font-family: 'Orbitron', sans-serif;
  align-items: center;
  margin-left: auto;
}

.links p {
  margin-right: 20px;
  letter-spacing: 3px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

.dropdown-content {
  display: none; 
  flex-direction: column;
  align-items: center;
  opacity: 0; 
  transform: translateY(-20px); 
  transition: opacity 0.3s ease, transform 0.3s ease; 
  position: absolute; 
  right: 0;
  padding: 10px;
  background-color: black;
}

.dropdown-content p {
  padding: 8px 3px;
  font-family: 'Orbitron', sans-serif;
  letter-spacing: 3px;
}

.dropdown-button {
  display: block;
  cursor: pointer;
  z-index: 2;
  margin-left: auto;
}


.dropdown-menu.open .dropdown-content {
  display: flex;
  opacity: 1;
  transform: translateY(0);
}

@media screen and (max-width: 768px) {
  .header {
    display: flex;
    flex-direction: row;
  }

  .links {
    display: none;
  }

  .dropdown-button {
    display: block;
    margin-left: auto;
  }

  .dropdown-menu {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .dropdown-content {
    position: relative; 
    width: auto; 
    background-color: #f9f9f9;
    padding: 0px;
    justify-content: center;
    align-items: center;
  }
}
/* Hero */

.container {
  border: 1px solid white;
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero {
  width: 90%;
  height: 80%;
  color: black;
  font-family: 'Orbitron', sans-serif;
  border: solid white 1px;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
}

.hero h1 {
  letter-spacing: 2px;
  font-size: 25px;
}

#typing-text {
  font-size: 30px;
}

#typing-text::after {
  content: "|"; 
  animation: cursor-animation 0.7s infinite;
}

@keyframes cursor-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.intro {
  border: solid white 1px;
  height: 280px;
  width: 400px;
}

.particleContainer {
  border: solid white 1px;
  width: 400px;
  height: 280px;
}



@media (max-width: 1000px) {
  .container {
    height: auto;
  }
  .hero {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .intro {
    width: 400px;
    height: 280px;
    margin-bottom: 20px;
  }
  
  .particleContainer {
    width: 400px;
    height: 280px;
  }
}

/* Recent Projects */

.recentProjects {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.recentProjects h1 {
  color: black;
  text-align: center;
  font-family: 'Orbitron', sans-serif;
  font-size: x-large;
  border-bottom: 2px solid black;
  width: max-content;
  margin-bottom: 30px;
}

.projectGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.projectCard {
  margin: 10px 20px
}

.projectsH1 {
  margin-top: 20px;
}

/* Artel */

.artel {
  width: 300px;
  height: 340px;
  border: 4px solid #a28357;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}


.slideshow {
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-bottom: 4px solid #a28357;
}

.slideshowImg {
  width: 100%;
  height: 100%;
}

.artelPostBottom {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  justify-content: space-between;
  background-color: white;
  font-family: 'Bruno Ace SC', cursive;
}

.iconContainer {
  display: flex;
  align-items: center;
  
}

.icon {
  width: 25px;
  height: 25px;
  margin-left: 8px;
}

.artelPostTag {
  margin-right: 8px;
  font-weight: bold;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  margin: 5px;
  text-align: center;
  height: 35px;
  background: linear-gradient(to right, #3B82F6, #8B5CF6);
  color: white;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-weight: 500;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  font-family: 'Titillium Web', sans-serif;
}

.button:hover {
  background: linear-gradient(to right, #6B46C1, #9333EA);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.description {
  width: 300px;
  height: 150px;
  text-align: center;
  background-color: #F3F4F6;
  padding: 16px;
  border-radius: 4px;
  overflow: auto;
}

.description p {
  margin: 0;
  font-size: 14px;
  color: #4B5563;
}

.recipeImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pokeohImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Footer */

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  background-color: black;
  align-items: center;
}

.linkedin {
  margin-top: 3px;
}

/* About Me */
.aboutMe {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  justify-content: center;
}
.aboutMeH1 {
  color: black;
  text-align: center;
  font-family: 'Orbitron', sans-serif;
  font-size: x-large;
  border-bottom: 2px solid black;
  width: max-content;
  margin-bottom: 30px;
  margin-top: 20px;
}


/* About Me Text */
.aboutMeContainer {
  display: flex;
  flex-direction: row;
}

.aboutMeText {
  width: 450px;
  height: 450px;
  /* border: 1px black solid; */
  margin: 30px;
  text-align: center;
}

.textTitle {
  font-size: 30px;
  font-weight: bold;
  /* font-family: 'Orbitron', sans-serif; */
  font-family: 'Bruno Ace SC', cursive;
}

.textDescription {
  /* font-family: 'Orbitron', sans-serif; */
  font-family: 'Bruno Ace SC', cursive;
  font-size: 20px;
}

.skillicons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.skillicons img {
  width: 50px;
  margin: 5px;
}

.summary {
  padding: 20px;
  /* font-family: 'Orbitron', sans-serif; */
  font-family: 'Bruno Ace SC', cursive;
}

.background {
  padding: 20px;
  /* font-family: 'Orbitron', sans-serif; */
  font-family: 'Bruno Ace SC', cursive;
  height: 400px;
  overflow: scroll;
}

.hobbies {
  padding: 20px;
  font-family: 'Bruno Ace SC', cursive;
}
.resumeDiv {
  margin: 8px 0 15px 0;
  font-family: 'Bruno Ace SC', cursive;
  font-weight: bold;
  color: #3B82F6;
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .aboutMeContainer {
    flex-direction: column;
  }

  .background {
    padding: 20px;
    /* font-family: 'Orbitron', sans-serif; */
    font-family: 'Bruno Ace SC', cursive;
    height: 300px;
    overflow: scroll;
  }

  .aboutMeText {
    width: 380px;
    height: 450px;
    /* border: 1px black solid; */
    margin: 30px;
    text-align: center;
  }

  .iconsbackground {
    margin-top: 15px;
  }
}
/* GameBoy */

.gameBoy {
  height: 550px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  /* background: #c92438;
  background: -webkit-linear-gradient(to right, #c92438, #a50026);
  background: linear-gradient(to right, #c92438, #a50026); */
  background-color: #7f5a83;
  background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);

  border: 2px solid black;
}

.screenBorder {
  width: 300px;
  height: 300px;
  border: 2px solid black; 
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dadada;
  border-radius: 20px;
  flex-direction: column;
  margin-top: 20px;
}

.screen {
  font-family: 'Orbitron', sans-serif;
  width: 260px;
  height: 260px;
  border: 2px solid black;
  border-radius: 20px;
  background-color: white;
  text-align: center;
  color: black;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.screen img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.buttons {
  height: 200px;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dpad {
  width: 60px;
  height: 60px;
  position: relative;
  transform: scale(1.4);
  margin-left: 10px;
}

.dpad div {
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: #a0a0a0;
  border: 2px solid #606060;
  overflow: hidden;
  transition: box-shadow 0.1s;
}

.dpad-up {
  top: 0px;
  left: 20px;
}

.dpad-down {
  bottom: 0px;
  left: 20px;
  
}

.dpad-left {
  top: 20px;
  left: 0px;
}

.dpad-right {
  top: 20px;
  right: 0px;
}

.dpad-middle {
  top: 20px;
  left: 20px;
}

.ab {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
}
.aButton, .bButton {
  margin: 10px;
}

.aButton {
  margin: 0 0 0 15px;
}

.bButton {
  margin: 20px 15px 0 0;
}

.buttonA, .buttonB {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #606060;
  color: #A0A0A0;
  font-size: larger;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);

}
.up, .down, .left, .right {
  height: 100%;
  width: 100%;
  background-color: #A0A0A0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.buttonA:active, .buttonB:active {
  transform: translateY(2px);
  background-color: #4c4c4c; 
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.dpad div:active {
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.5); 
}

.blink-animation {
  animation: blinkAnimation 0.5s infinite; /* Adjust the animation duration as needed */
}

@keyframes blinkAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}